var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "white--text text-h5 mb-4",
                      attrs: { color: "secondary" },
                    },
                    [_vm._v(" Register new sales team ")]
                  ),
                  _vm.isInputInvalid
                    ? _c("AWarning", {
                        attrs: { message: _vm.messageInputInvalid },
                      })
                    : _vm._e(),
                  _c("ASuccessThree", { attrs: { api: this.api } }),
                  _c(
                    "v-col",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: "Team name",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              counter: "100",
                              outlined: "",
                              label: "description",
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-autocomplete", {
                        attrs: {
                          clearable: "",
                          outlined: "",
                          dense: "",
                          filled: "",
                          "small-chips": "",
                          "item-text": "staff_name",
                          "item-value": "email",
                          items: _vm.leaderOptions,
                          label: "Leader",
                        },
                        model: {
                          value: _vm.form.leader,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "leader", $$v)
                          },
                          expression: "form.leader",
                        },
                      }),
                      _c("v-autocomplete", {
                        attrs: {
                          "deletable-chips": "",
                          required: "",
                          clearable: "",
                          outlined: "",
                          dense: "",
                          filled: "",
                          "small-chips": "",
                          items: _vm.membersOptions,
                          "item-text": "staff_name",
                          "item-value": "email",
                          label: "Member(s)",
                          multiple: "",
                        },
                        model: {
                          value: _vm.form.members,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "members", $$v)
                          },
                          expression: "form.members",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", plain: "" },
                              on: { click: _vm.redirectBack },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: _vm.api.isLoading,
                                color: "primary",
                              },
                              on: { click: _vm.validateInput },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }